var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background"},[_vm._m(0),_c('div',{staticClass:"grid-x align-center-middle"},[_c('div',{staticClass:"cell small-9 medium-3 text-center"},[_c('font-awesome-icon',{staticClass:"fa-3x",attrs:{"icon":"filter","aria-hidden":"true"}})],1),_vm._m(1),_c('div',{staticClass:"cell small-9 medium-3 text-center"},[_c('font-awesome-icon',{staticClass:"fa-3x",attrs:{"icon":"check-square","aria-hidden":"true"}})],1),_vm._m(2),_c('div',{staticClass:"cell small-9 medium-3 text-center"},[_c('font-awesome-icon',{staticClass:"fa-3x",attrs:{"icon":['far', 'square'],"aria-hidden":"true"}})],1),_vm._m(3),_c('div',{staticClass:"cell small-9 medium-3 text-center"},[_c('font-awesome-icon',{staticClass:"fa-3x",attrs:{"icon":"map-marker-alt","aria-hidden":"true"}})],1),_vm._m(4),_c('div',{staticClass:"cell small-9 medium-3 text-center"},[_c('font-awesome-icon',{staticClass:"fa-3x",attrs:{"icon":"search","aria-hidden":"true"}})],1),_vm._m(5),_c('div',{staticClass:"cell small-9 medium-3 text-center"},[_c('img',{staticClass:"street-view-image",attrs:{"src":'images/cyclomedia_blue.png'}})]),_vm._m(6),_c('div',{staticClass:"cell small-9 medium-3 text-center"},[_c('font-awesome-icon',{staticClass:"fa-3x",attrs:{"icon":"info-circle","aria-hidden":"true"}})],1),_vm._m(7)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"text-center"},[_c('b',[_vm._v("How to use OpenMaps")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-15 medium-21 text-div"},[_c('p',[_c('b',[_vm._v("Filter the list of layers available in the left panel.")])]),_c('p',[_vm._v("Type in the box for the first filter to filter by text. Select a category from the second filter to filter by category.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-15 medium-21 text-div"},[_c('p',[_c('b',[_vm._v("Toggle on and off map layers by clicking on the checkboxes in the layer panel.")])]),_c('p',[_vm._v("You can toggle on as many layers as you want, and they will all show on the map.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-15 medium-21 text-div"},[_c('p',[_c('b',[_vm._v("If a checkbox is grayed out, zoom in further on the map and it will become active.")])]),_c('p',[_vm._v("Many of the layers will not show when the map is zoomed out too far.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-15 medium-21 text-div"},[_c('p',[_c('b',[_vm._v("Click on any feature that appears on the map to get a popup with that feature's info.")])]),_c('p',[_vm._v("If you have turned on overlapping layers, you will be able to click through multiple popups of info.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-15 medium-21 text-div"},[_c('p',[_c('b',[_vm._v("Search for an address or intersection.")])]),_c('p',[_vm._v("The map will move to the location that you have searched")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-15 medium-21 text-div"},[_c('p',[_c('b',[_vm._v("Toggle on and off street view")])]),_c('p',[_vm._v("Half of the map panel will be taken up by the street view. If you are zoomed in far enough, circles will appear along the streets in the map. Click on a circle to change the street view location to that point.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-15 medium-21 text-div"},[_c('p',[_c('b',[_vm._v("Learn more about and download data.")])]),_c('p',[_vm._v("Click this icon to be linked to the metadata page for any dataset.")])])
}]

export { render, staticRenderFns }